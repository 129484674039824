import { useState } from "react";
import { Input, Select, TextArea } from "./input";
import Modal from "./Modal";

const ContactModal = props => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [interest, setInterest] = useState("");
  const [info, setInfo] = useState("");

  const options = [
    { name: "Both", value: "riby", id: 1 },
    { name: "Riby Marketplace Limited (RMP)", value: "rmp", id: 2 },
    { name: "Radeus Business Tech Limited", value: "radeus", id: 3 },
  ];

  const submitFormHandler = e => {
    e.preventDefault();

    if (
      name.length === 0 &&
      email.length === 0 &&
      number.length === 0 &&
      interest.length === 0 &&
      info.length === 0
    ) {
      return;
    }
  };

  const closeModalHandler = () => {};

  return (
    <Modal onClose={props.onClose}>
      <div
        className="absolute top-4 right-8 md:top-[72px] md:right-[114px] text-2xl"
        onClick={props.onClose}
      >
        &times;
      </div>
      <h3 className="text-2xl font-semibold text-secondary mb-[47px] text-center">
        Contact Us
      </h3>

      <div className="flex flex-col gap-[45px]">
        <div className="flex gap-5">
          <div className="text-[#737070] text-[20px] leading-[36px] w-[150px] ">
            Email Address:{" "}
          </div>
          <div className="text-[#152C4F] text-[20px] leading-[36px] w-[300px]">
            info@ribyinc.com
          </div>
        </div>
        <div className="flex gap-5">
          <div className="text-[#737070] text-[20px] leading-[36px] w-[150px] ">
            Phone Number:{" "}
          </div>
          <div className="flex flex-col w-[300px]">
            <div className="text-[#152C4F] text-[20px] leading-[36px]">
              +1 631 615 9509
            </div>{" "}
            <div className="text-[#152C4F] text-[20px] leading-[36px]">
              +234 809 222 2109
            </div>
          </div>
        </div>
        <div className="flex gap-5">
          <div className="text-[#737070] text-[20px] leading-[36px] w-[150px] ">
            Address:{" "}
          </div>
          <div className="flex flex-col w-[300px]">
            <div className="">
              <div className=" text-meduimLightGrey text-[15px] leading-[36px]">
                U.S.A{" "}
              </div>
              <div className="text-[#152C4F] text-[20px] leading-[36px]">
                132 W 31st St. 9th Floor, New York, NY
              </div>
            </div>
            <div className="">
              <div className=" text-meduimLightGrey text-[15px] leading-[36px]">
                Nigeria{" "}
              </div>
              <div className="text-[#152C4F] text-[20px] leading-[36px]">
                Block G, House 4, Moore road Yaba, Lagos
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center items-center">
        <button className="h-[56px] w-[221px] bg-button rounded text-sm text-white mt-[25px]">
          <a
            target="_blank"
            rel="noreferrer"
            href="mailto:info@ribyinc.com"
            className=""
          >
            Send an Email{" "}
          </a>
        </button>
      </div>
    </Modal>
  );
};

export default ContactModal;
