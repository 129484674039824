import React, { Fragment } from "react";
import ReactDOM from "react-dom";
const Backdrop = props => {
  return (
    <div
      className="fixed top-0 left-0 w-full h-screen bg-softBlack z-40"
      onClick={props.onClose}
    />
  );
};

// const Modal = props => {
//   return (

//       <div className="fixed top-0 left-0 w-full h-longscreen bg-softBlack z-[900]" >
//         {props.children}
//       </div>

//   );
// };

const ModalOverlay = props => {
  return (
    <div className="px-8 py-4 md:px-[114px] md:py-[72px] fixed top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2 bg-white z-50 w-[90vw] md:w-[70%] lg:w-1/2 shadow-[ 2px 8px rgba(41, 6, 236, 0.25)] rounded-2xl max-h-[90vh]  max-w-[700px]  overflow-scroll">
      <div>{props.children}</div>
    </div>
  );
};
const portalElement = document.getElementById("overlays");
const Modal = props => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay>{props.children}</ModalOverlay>,
        portalElement
      )}
    </Fragment>
  );
};

export default Modal;
