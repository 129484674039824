import partner from "../assets/Images/partner.svg";
import partner2 from "../assets/Images/partner2.svg";
import partner3 from "../assets/Images/partner3.svg";
import cobanking from "../assets/Images/cobankMockup.svg";
import boi from "../assets/Images/boi.svg";
import sterling from "../assets/Images/sterling.svg";
import un from "../assets/Images/un.svg";
import union from "../assets/Images/union.svg";
import worldbank from "../assets/Images/worldbank.svg";
import gtbank from "../assets/Images/gtbank.svg";
import google from "../assets/Images/google.svg";
import alibaba from "../assets/Images/alibaba.svg";
import providus from "../assets/Images/providus.svg";
import linx from "../assets/Images/MAC.svg";
import dynamo from "../assets/Images/dynamo.svg";
import agent from "../assets/Images/agent.svg";
import mail from "../assets/Images/mail.svg";
import phone from "../assets/Images/phone.svg";
import location from "../assets/Images/location.svg";
import village from "../assets/Images/village.svg";
import amazon from "../assets/Images/amazon.svg";
import seventeen from "../assets/Images/1776.svg";
import arrow from "../assets/Images/arrow.svg";

export const hero = [
  { id: 1, name: "Digital Platforms", line: true },
  { id: 2, name: "Financial Services", line: true },
  { id: 3, name: "Agent Banking", line: true },
  { id: 4, name: "Inclusive Growth", line: false },
];

export const traction = [
  { number: "8.5M", text: "End Users Managed", id: 1 },
  { number: "4.5M", text: "End Users Served", id: 2 },
  { number: "250K", text: "Retail Users", id: 3 },
  { number: "2.5K", text: "Agents", id: 4 },
  { number: "$5m+", text: "Revenue and Invested Capital", id: 5 },
  // { number: "$1.24M", text: "Equity and Equity-free Funding", id: 6 },
];
export const partnerList = [
  { img: boi, id: 1 },
  { img: alibaba, id: 2 },
  { img: un, id: 3 },
  { img: union, id: 4 },
  { img: gtbank, id: 5 },
  { img: sterling, id: 6 },
  { img: worldbank, id: 7 },
  { img: google, id: 8 },
  { img: providus, id: 9 },
  { img: amazon, id: 10 },
  { img: village, id: 11 },
  { img: seventeen, id: 12 },
];

export const business = [
  {
    title: "Riby Marketplace Limited (RMP)",
    text: "Riby is Nigeria’s foremost digital platform for cooperatives and trade groups. We help members of these groups access financial services like savings and loans through our platform.",
    website: "https://riby.ng/",
    product: [
      {
        id: 1,
        name: "CoBanking",
        des: "Everybody deserves a chance at financial success. At Riby, we provide the building blocks for cooperatives and associations to empower their members to create better lives for themselves.",
        img: cobanking,
        website: "https://riby.ng/cobanking.php",
      },
      {
        id: 2,
        name: "Agent App",
        des: "The Riby Agent Network is a Riby's on-the-ground network of trained agents, skilled at offering value and support to customers all across Nigeria, far beyond the reaches of technology.",
        img: agent,
        website: "https://riby.ng/agent/index.html",
      },
    ],
  },
  {
    title: "Radeus Business Tech Limited ",
    text: "Radeus Designs, Develops and Deploys Technology Solutions that Enables Access to Collective Growth.",
    website: "radeusbusiness.com",
    product: [
      {
        id: 1,
        name: "LinX",
        des: "We offer business owners a full range of operations and marketing services to help reach more customers, improve efficiency, and increase revenue ",
        img: linx,
        website: "https://linxplatform.com/",
      },
      {
        id: 2,
        name: "Dynamo",
        des: "Dynamo is a data collection platform, that allows users create specific data form and verification process that best meet their objective ",
        img: dynamo,
        website: "",
      },
    ],
  },
];
export const footerItems = [
  {
    id: 2,
    name: "GENERAL ENQUIRY",
    list: [
      { id: 1, name: "info@ribyinc.com", icon: mail },
      { id: 2, name: "US: +1 631 615 9509", icon: phone },
      { id: 3, name: "NG: +234 809 222 2109", icon: phone },
      
    ],
  },
  {
    id: 2,
    name: "OUR OFFICES",
    list: [
      { id: 1, name: "132 W 31ST St. 9th Floor, New York, NY", icon: location },
      {
        id: 2,
        name: "Block G, House 4, Moore Road Yaba, Lagos",
        icon: location,
      },
    ],
  },
];
