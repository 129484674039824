import ribyLogo from "../assets/Images/RLogo.svg";

const Navbar = props => {
  return (
    <div className=" w-full  h-[120px] bg-darkPrimaryLight hover:bg-darkPrimary px-[30px] py-[20px] md:px-[80px] md:py-[30px] lg:px-[120px] lg:py-[30px] ">
      <div className="lg:max-w-[1400px] lg:mx-auto flex  justify-between items-center">
        <div>
          <img src={ribyLogo} />
        </div>
        <button
          type="submit"
          className="h-[48px] w-[130px] bg-secondary rounded text-sm text-white "
          onClick={props.onClick}
        >
          Contact Us
        </button>
      </div>
    </div>
  );
};

export default Navbar;
