import Form from "./components/Form";
import ContactModal from "./components/ContactModal";
import CareerForm from "./components/CarreerForm";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import Top from "./assets/Images/top.svg";
import Left from "./assets/Images/left.svg";
import Right from "./assets/Images/right.svg";
import Mac from "./assets/Images/MAC.svg";
import First from "./assets/Images/first.png";
import Second from "./assets/Images/second.png";
import partner from "./assets/Images/partner.svg";
import backImage from "./assets/Images/backImage.svg";
import aboutLogo from "./assets/Images/aboutLogo.svg";
import investor from "./assets/Images/relations.svg";
import arrow from "./assets/Images/arrow.svg";
import Footer from "./components/Footer";
import { Fade, Zoom, Rotate, Reveal, Slide } from "react-reveal";
import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation, Autoplay } from "swiper";

import {
  partnerList,
  traction,
  business,
  partnerListReverse,
} from "./components/utils";

import { useState } from "react";

function Home() {
  const [openModal, setOpenModal] = useState(false);
  const [openCareerModal, setOpenCareerModal] = useState(false);
  const [openContactModal, setOpenContactModal] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 600);
  const [show, setShow] = useState(false);
  const handleClick = () => {
    setShow(true);
  };

  useEffect(() => {
    handleClick();
  }, []);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 600);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  const openPartnerModalHandler = () => {
    setOpenModal(true);
  };
  const closePartnerModalHandler = () => {
    setOpenModal(false);
  };
  const openCareerModalHandler = () => {
    setOpenCareerModal(true);
  };
  const closeCareerModalHandler = () => {
    setOpenCareerModal(false);
  };
  const openContactModalHandler = () => {
    setOpenContactModal(true);
  };
  const closeContactModalHandler = () => {
    setOpenContactModal(false);
  };
  return (
    <>
      {openModal && <Form onClose={closePartnerModalHandler} />}
      {openContactModal && <ContactModal onClose={closeContactModalHandler} />}
      {openCareerModal && <CareerForm onClose={closeCareerModalHandler} />}
      <div className="w-screen">
        <Navbar onClick={openContactModalHandler} />
        <HeroSection />
        <div className="relative my-16 py-[44px] px-[30px]  md:px-[80px]  lg:px-[120px] z-10 flex flex-col md:flex-row gap-[44px] items-center justify-center lg:max-w-[1400px] lg:mx-auto">
          <div className="hidden md:block">
            <img src={aboutLogo} />
          </div>
          <div className="md:w-[60%]" id="about">
            <div className="">
              <h4 className="text-[24px] leading-[36px] text-grey  font-semibold text-center md:text-left ">
                ABOUT US
              </h4>
              <div className="w-full   mx-auto ">
                <p className="text-secondary text-base text-center md:text-left  ">
                  Riby provides digital and financial services platforms to
                  retail customers and enterprise clients.
                </p>
              </div>
            </div>
            <div className="mt-[24px]">
              <h4 className="text-[24px] leading-[36px] text-grey font-semibold  text-center md:text-left">
                Our Mission
              </h4>
              <div className="w-full  mx-auto ">
                <p className="text-secondary text-base  text-center md:text-left ">
                  We are on a mission to create prosperous communities across
                  Africa by helping small businesses, communities, trade groups
                  and cooperatives (formal and informal) to digitize their
                  operations and get access to financial services (savings,
                  loans, and payment) through our digital platforms
                  (Web/Mobile/APIs) and offline channels (Agents, USSD, PoS, NFC
                  and QR-Codes).
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="relative flex flex-col gap-12 lg:flex-row items-center lg:items-start lg:justify-start lg:h-[469px] px-[30px]  md:px-[80px]  lg:px-[197px] lg:max-w-[1400px] lg:mx-auto ">
          <div className="w-full lg:w-[504px] mt-[92px] z-10">
            <h1 className="text-center lg:text-left text-5xl text-secondary text-semibold ">
              Enabling access to collective growth
            </h1>
          </div>
          <div className="hidden md:block   lg:absolute w-full   lg:top-0 lg:right-0 lg:w-[900px] lg:ml-[-45px] h-[250px] lg:h-full   ">
            <div className="absolute left-[152px]   w-[424px] h-[283px]  ">
              <img src={Top} />
            </div>
            <div className="absolute top-[92px] right-[25px]  z-10  w-[397px] h-[265px] ">
              <img src={Right} />
            </div>
            <div className="absolute left-8 bottom-0  z-20 w-[482px] h-[246px] ">
              <img src={Left} />
            </div>
          </div>
          <div className="block md:hidden relative w-full h-[250px] lg:h-full   ">
            <div className="absolute top-0 left-1/2 -translate-x-1/2  w-[180px] h-[150px] object-cover ">
              <img src={Top} />
            </div>
            <div className="absolute top-[80px] right-[20px]   w-[180px] h-[150px] object-cover z-10 ">
              <img src={Right} />
            </div>
            <div className="absolute top-[80px] left-[20px]  z-20 w-[180px] h-[150px] object-cover  ">
              <img src={Left} />
            </div>
          </div>
        </div>
        <div className="my-16  px-[30px]  md:px-[80px]  lg:px-[120px] lg:max-w-[1400px] lg:mx-auto">
          <h4 className="text-[24px] leading-[36px] text-grey text-center font-semibold mb-[41px]">
            Our Traction to Date
          </h4>
          <div className="flex flex-wrap gap-[20px] justify-center items-center ">
            {traction.map((item) => (
              <Fade left when={show}>
                <div
                  className="rounded-lg shadow-1xl w-full  md:w-[275px] h-[150px] bg-white flex flex-col justify-center items-center gap-3"
                  key={item.id}
                >
                  <h1 className="text-secondary text-2xl md:text-4xl leading-[36px] font-semibold">
                    {item.number}
                  </h1>
                  <h5 className="text-[10px] md:text-[20px] leading-4 md:leading-[36px] text-[#767676] font-medium text-center">
                    {item.text}
                  </h5>
                </div>
              </Fade>
            ))}
          </div>
        </div>
        <div
          className="relative bg-background my-16 py-[105px]  px-[30px]  md:px-[80px]  lg:px-[120px] h-max overflow-y-hidden"
          id="business"
        >
          <div className="relative  mb-[52px] z-10">
            <h4 className="text-[24px] leading-[36px] text-secondary text-center font-semibold">
              OUR BUSINESSES
            </h4>
            <div className="w-full md:w-{70%} lg:w-[640px]  mx-auto ">
              <p className="text-base text-secondary text-center ">
                Building Digital Platforms that ensure access to wealth-creating
                financial services and programs is the core mission for our
                business.
              </p>
            </div>
          </div>
          <div className="relative flex flex-col md:flex-row gap-[20px] items-center justify-center z-10">
            {business.map((biz) => (
              <div
                key={biz.id}
                className="w-full md:w-[590px] h-auto lg:h-[650px]  rounded-2xl px-[32px] py-[48px]"
              >
                <h2 className="text-secondary text-[24px] leading-[36px] font-semibold mb-3">
                  {biz.title}
                </h2>
                <p className="text-secondary text-[16px] leading-[36px] md:h-[96px]">
                  {biz.text}
                </p>
                <div className=" w-full flex justify-end items-center mt-2">
                  <a
                    href={biz.website}
                    className="italic underline-offset-auto text-[14px] leading-[36px] text-secondary text-right"
                  >
                    {" "}
                    Visit Website{" "}
                  </a>
                  <span className="ml-[2px]">
                    <img src={arrow} />
                  </span>
                </div>
                <div>
                  <h3 className="text-secondary text-[20px] leading-[26px] font-medium mt-[17px] ">
                    {" "}
                    Products
                  </h3>
                  {biz.product.map((item) => (
                    <div
                      key={item.id}
                      className="flex gap-[20.45px]  mt-[35px]"
                    >
                      <div className="w-[213px]">
                        <img
                          src={item.img}
                          className="object-contain w-full h-full"
                        />
                      </div>
                      <div className="">
                        <h6 className="text-secondary text-[16px] leading-[36px] font-semibold">
                          {item.name}
                        </h6>
                        <p className="text-secondary text-[12px] leading-[26px] ">
                          {item.des}
                        </p>
                        <div className=" w-full flex justify-end items-center mt-2">
                          <a
                            href={item.website}
                            className="italic text-[12px] leading-[36px] text-secondary text-right"
                          >
                            {" "}
                            Learn More{" "}
                          </a>
                          <span className="ml-[2px]">
                            <img src={arrow} />
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="absolute top-0 left-0 w-full h-full z-1 ">
            <img src={backImage} />
          </div>
        </div>
        <div className="my-16 py-[105px]   bg-primary ">
          <div className="lg:max-w-[1400px] lg:mx-auto">
            <h4 className="text-[24px] leading-[36px] text-grey text-center font-semibold mb-12">
              OUR PARTNERS
            </h4>
            <div className="max-w-full py-[10px]">
              <Swiper
                slidesPerView={isDesktop ? 5 : 2}
                spaceBetween={30}
                slidesPerGroup={isDesktop ? 5 : 2}
                loop={true}
                loopFillGroupWithBlank={true}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className="flex gap-[20px]"
              >
                {partnerList.map((item) => (
                  <SwiperSlide>
                    <div
                      key={item.id}
                      className="w-[161px] h-[101px] lg:w-[246px] lg:h-[121px] rounded-xl bg-white shadow-3xl flex items-center justify-center"
                    >
                      <img
                        src={item.img}
                        className="object-contain w-full h-full"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
        <div className="my-16 py-[105px]  px-[30px]  md:px-[80px]  lg:px-[120px]">
          <h4 className="text-[24px] leading-[36px] text-grey text-center font-semibold mb-[36px]">
            PARTNER BUSINESSES
          </h4>
          <div className="flex flex-col md:flex-row gap-[20px] items-center justify-center">
            <Zoom when={show}>
              <div className="p-[20px] flex gap-[19px] w-full lg:h-[285px] md:w-[590px] bg-white rounded-lg shadow-2xl">
                <div className="lg:w-[230px] lg:h-[261px] object-cover">
                  <img src={First} className="lg:w-[230px] lg:h-[261px]" />
                </div>

                <div className="lg:w-[310px] relative h-[380px] md:h-auto">
                  <h2 className="text-[24px] leading-[36px] text-secondary font-semibold">
                    Tradebuza
                  </h2>
                  <p className="text-secondary text-[14px] leading-[36px]">
                    Our solutions cater to a broad range of clients from large
                    growers, multi-farm corporations, ag-buyers, food
                    conglomerates, ag-financing, and insurance companies, to
                    government organizations.
                  </p>
                  <div className=" w-full flex justify-end items-center absolute right-[10px]  bottom-[2px]  ">
                    <a
                      href="https://www.tradebuza.com/"
                      className="italic text-[12px] leading-[36px] text-secondary"
                    >
                      {" "}
                      Learn More
                    </a>
                    <span className="ml-[2px]">
                      {" "}
                      <img src={arrow} />
                    </span>
                  </div>
                </div>
              </div>
            </Zoom>
            <Zoom when={show}>
              <div className="p-[20px] flex gap-[19px] w-full lg:h-[285px] md:w-[590px] bg-white rounded-lg shadow-2xl">
                <div className="lg:w-[230px] lg:h-[261px] object-cover">
                  <img src={Second} className="lg:w-[230px] lg:h-[261px]" />
                </div>
                <div className="lg:w-[310px] relative h-[380px] md:h-auto">
                  <h2 className="text-[24px] leading-[36px] text-secondary font-semibold">
                    Retailar
                  </h2>
                  <p className="text-secondary text-[14px] leading-[36px]">
                    Retailar is a platform for retailars to access discounted, a
                    faster and stress-free delivery of stocks from FMCG
                    partners.
                  </p>
                  <div className=" w-full flex justify-end items-center absolute right-[10px] bottom-[2px] ">
                    <a
                      href="https://myretailar.com/"
                      className="italic  text-[12px] leading-[36px] text-secondary text-right"
                    >
                      {" "}
                      Learn More{" "}
                    </a>
                    <span className="ml-[2px]">
                      <img src={arrow} />
                    </span>
                  </div>
                </div>
              </div>
            </Zoom>
          </div>
        </div>
        <div className="my-16 py-[105px]  px-[30px]  md:px-[80px]  lg:px-[120px] lg:max-w-[1400px] lg:mx-auto">
          <h2 className="text-center md:w-[504px] text-secondary mb-[136px] text-[48px] leading-[59px] font-semibold mx-auto">
            Let us build wealth together
          </h2>
          <div className="flex flex-col md:flex-row gap-[44px] bg-secondaryMeduim p-9">
            <div className="lg:w-[600px] lg:h-[440px]">
              <img src={investor} className="object-contain w-full h-full" />
            </div>
            <div className="lg:w-[435px]">
              <h3 className="text-white text-2xl mb-[12px]">
                Investor Relations
              </h3>
              <p className="text-white text-[16px] leading-[36px]">
                Investors who invest through Riby Inc, automatically invest in
                both Riby Marketplace Limited and Radeus Business Tech Limited.
                Alternatively, where so desired, an investor can invest directly
                in either of the two operating businesses.
              </p>
              <p>
                Riby Inc is registered in Delaware, USA. Riby Marketplace
                Limited and Radeus Business Tech Limited (our Africa operating
                entities) are registered with the Corporate Affairs Commission
                in Nigeria.
              </p>
              <button
                type="submit"
                className="h-[48px] w-[130px] bg-button rounded text-sm text-white mt-[57px]"
                onClick={openPartnerModalHandler}
              >
                Partner with Us
              </button>
            </div>
          </div>
        </div>
        <Footer
          careerClick={() => {
            setOpenCareerModal(true);
          }}
          contactClick={() => {
            setOpenContactModal(true);
          }}
          partnerClick={() => {
            setOpenModal(true);
          }}
        />
      </div>
    </>
  );
}
export default Home;
