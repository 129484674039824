import { useState } from "react";
import { Input, Select, TextArea } from "./input";
import Modal from "./Modal";

const Form = props => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [interest, setInterest] = useState("");
  const [info, setInfo] = useState("");

  const submitFormHandler = e => {
    e.preventDefault();

    if (
      name.length === 0 &&
      email.length === 0 &&
      number.length === 0 &&
      interest.length === 0 &&
      info.length === 0
    ) {
      return;
    }
  };

  const closeModalHandler = () => {};

  return (
    <Modal onClose={props.onClose}>
      <form
        className=" py-4 md:py-[72px] "
        onSubmit={submitFormHandler}
        s
      >
        <div
          className="absolute top-4 right-8 md:top-[72px] md:right-[114px] text-2xl"
          onClick={props.onClose}
        >
          &times;
        </div>
        <h3 className="text-2xl font-semibold text-secondary mb-[47px] text-center">
          Partner With Us
        </h3>
        <Input
          label="Full Name"
          id="name"
          value={name}
          name="name"
          placeholder="First Name Last Name"
          onChange={e => {
            setName(e.target.value);
          }}
        />
        <Input
          label="Email Address"
          id="email"
          value={email}
          name="email"
          placeholder="example@email.com"
          onChange={e => {
            setEmail(e.target.value);
          }}
        />
        <Input
          label="Phone Number"
          id="number"
          value={number}
          name="number"
          placeholder="Phone Number"
          onChange={e => {
            setNumber(e.target.value);
          }}
        />
        <Select
          label="Business Interest"
          id="interest"
          value={interest}
          name="interest"
          placeholder=""
          options={[
            { name: "Business Interest", value: "", id: 1 },
            { name: "All", value: "riby", id: 2 },
            { name: "-Riby Marketplace Limited (RMP)", value: "rmp", id: 3 },
            { name: "-Radeus Business Tech Limited", value: "radeus", id: 4 },
          ]}
          onChange={e => {
            setInterest(e.target.value);
          }}
        />
        <TextArea
          label="Additional Information"
          id="info"
          value={info}
          name="info"
          placeholder="Tell us more about Your Business interest"
          onChange={e => {
            setInfo(e.target.value);
          }}
        />
        <div className="w-full flex justify-center items-center">
          <button className="h-[56px] w-[221px] bg-button rounded text-sm text-white ">
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default Form;
