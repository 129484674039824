import Africa from "../assets/Images/africa.svg";
import { Fade, Zoom, Rotate, Reveal, Slide } from "react-reveal";
import { useEffect, useState } from "react";

import { hero } from "./utils";

const HeroSection = () => {
  const [show, setShow] = useState(false);
  const handleClick = () => {
    setShow(true);
  };

  useEffect(() => {
    handleClick();
  }, []);
  return (
    <div className="w-full  relative bg-primary px-[30px] py-[20px] h-auto md:h-[calc(100vh-120px)] md:px-[80px] md:py-[30px] lg:px-[120px] lg:py-[30px] lg:h-[calc(100vh-120px)] xl:max-h-[100vh]">
      <div className="lg:max-w-[1400px] lg:mx-auto relative flex flex-col items-center">
        <div className="absolute top-0 right-0 scale-60  md:scale-80 lg:scale-100">
          {" "}
          <Fade top when={show}>
            {" "}
            <img src={Africa} />{" "}
          </Fade>
        </div>
        <h1 className="hidden md:block font-semibold text-secondary text-2xl md:text-3xl lg:text-[64px] text-center md:leading-10 lg:leading-[96px] w-[90%] md:w-[80%] px-12 lg:w-[690px] md:mt-[89px] z-10 relative">
          <span className="block">Creating Prosperous</span>{" "}
          <span className="block"> Communities</span>{" "}
          <span className="block">
            Across{" "}
            <span className="text-red text-[64px] leading-[96px]"> Africa</span>
          </span>{" "}
        </h1>
        <h1 className="block md:hidden font-semibold text-secondary text-2xl text-center leading-10 w-[90%] z-10 my-[60px]">
          Creating Prosperous Communities Across{" "}
          <span className="text-red text-2xl leading-10">Africa</span>
        </h1>
        <div className="flex items-center justify-center gap-4 w-[90%] md:w-[80%] lg:w-[895px]  mt-20 md:mt-8 z-10">
          {hero.map(item =>
            item.line ? (
              <Reveal bottom when={show}>
                <div
                  key={item.id}
                  className="text-sm md:text-[20px] leading-[30px] text-secondary pr-4 border-r border-secondary md:border-darkPrimary"
                >
                  {item.name}
                </div>
              </Reveal>
            ) : (
              <Reveal bottom>
                {" "}
                <div
                  key={item.id}
                  className="text-sm md:text-[20px] leading-[30px] text-secondary "
                >
                  {item.name}
                </div>
              </Reveal>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
