export const Input = props => {
  return (
    <div className="flex flex-col gap-2 mb-6">
      <label htmlFor={props.id} className="secondary text-sm font-medium">
        {props.label}
      </label>
      <input
        id={props.id}
        onChange={props.onChange}
        type="text"
        value={props.value}
        name={props.name}
        placeholder={props.placeholder}
        className="h-[51px] w-full bg-softGrey rounded border border-offWhite p-[17px]"
      />
    </div>
  );
};
export const Select = props => {
  return (
    <div className="flex flex-col gap-2 mb-6">
      <label htmlFor={props.id} className="secondary text-sm font-medium">
        {props.label}
      </label>
      <select
        name={props.name}
        id={props.id}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        className="h-[51px] w-full md:w-[426px] bg-softGrey rounded border border-offWhite p-[15px]"
      >
        {props.options.map(option => (
          <option
            value={option.value}
            key={option.id}
            className="text-softGrey"
          >
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};
export const TextArea = props => {
  return (
    <div className="flex flex-col gap-2 mb-6">
      <label htmlFor={props.id} className="secondary text-sm font-medium">
        {props.label}
      </label>
      <textarea
        id={props.id}
        onChange={props.onChange}
        type="text"
        value={props.value}
        name={props.name}
        placeholder={props.placeholder}
        className="h-[130px] w-full md:w-[426px] bg-softGrey rounded border border-offWhite p-[17px]"
      />
    </div>
  );
};
