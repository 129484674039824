import footerBack from "../assets/Images/footerback.svg";
import ribyLogo from "../assets/Images/RLogo.svg";
import { footerItems } from "./utils";

const Footer = props => {
  return (
    <div className="relative px-[30px]  md:px-[80px]  lg:px-[197px]  lg:gap-[97px]  bg-background py-[54px] overflow-hidden">
      <div className="relative lg:max-w-[1400px] lg:mx-auto grid grid-cols-2 md:grid-cols-4">
        <div className="self-center z-20 relative w-[100px] md:w-auto">
          <img src={ribyLogo} />
          <br />
          <br />
          <span className="text-[12px] md:text-[16px] text-secondary leading-9">
            Chat With Us <br />
            <img
              style={{ display: "inline-block" }}
              className="w-[20px]"
              src=" https://image.similarpng.com/very-thumbnail/2021/01/Whatsapp-icon-design-premium-vector-PNG.png"
            />{" "}
            +1 631 615 9509
          </span>
        </div>
        <div className="z-20 relative mt-6 md:mt-0">
          <div className="text-secondary text-[16px] md:text-[20px] font-semibold leading-[36px] mb-6">
            COMPANY
          </div>
          <ul className="flex flex-col gap-[10px] md:gap-[29px]">
            <li
              key="1"
              className="text-[12px] md:text-[16px] text-secondary leading-9"
            >
              <a href="#about" className="cursor-pointer">
                About Us
              </a>
            </li>
            <li
              key="2"
              className="text-[12px] md:text-[16px] text-secondary leading-9"
            >
              <a href="#business" className="cursor-pointer">
                Our Businesses
              </a>
            </li>
            <li
              key="2"
              className="text-[12px] md:text-[16px] text-secondary leading-9 cursor-pointer"
              onClick={() => props.careerClick()}
            >
              Career Opportunities
            </li>
            <li
              key="2"
              className="text-[12px] md:text-[16px] text-secondary leading-9 cursor-pointer"
              onClick={() => props.contactClick()}
            >
              Contact Us
            </li>
            <li
              key="2"
              className="text-[12px] md:text-[16px] text-secondary leading-9 cursor-pointer"
              onClick={() => props.partnerClick()}
            >
              Partner with Us
            </li>
          </ul>
        </div>
        {footerItems.map((foot) => (
          <div className="z-20 relative mt-6 md:mt-0" key={foot.id}>
            <div className="text-secondary text-[16px] md:text-[20px] font-semibold leading-[36px] mb-6 capitalize">
              {foot.name}
            </div>
            <ul className="flex flex-col gap-[10px] md:gap-[29px]">
              {foot.list.map((item) =>
                item.icon ? (
                  <li
                    className="text-[12px] md:text-[16px] text-secondary leading-9 flex items-center"
                    key={item.id}
                  >
                    <div className="mr-[27.29px] h-full">
                      <img src={item.icon} />
                    </div>
                    <div>{item.name}</div>
                  </li>
                ) : item.href ? (
                  <li
                    key={item.id}
                    className="text-[12px] md:text-[16px] text-secondary leading-9"
                  >
                    <a href={item.href}>{item.name}</a>
                  </li>
                ) : (
                  <li
                    key={item.id}
                    className="text-[12px] md:text-[16px] text-secondary leading-9"
                  >
                    {item.name}
                  </li>
                )
              )}
            </ul>
          </div>
        ))}
        <hr />
        <div className="absolute top-0 left-0 w-full h-full z-1 hidden md:block">
          <img src={footerBack} />
        </div>
      </div>
    </div>
  );
};

export default Footer;
