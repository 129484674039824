import { useState } from "react";
import { Input, Select, TextArea } from "./input";
import Modal from "./Modal";

const CareerForm = props => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [interest, setInterest] = useState("");
  const [info, setInfo] = useState("");

  const submitFormHandler = e => {
    e.preventDefault();

    if (
      name.length === 0 &&
      email.length === 0 &&
      number.length === 0 &&
      interest.length === 0 &&
      info.length === 0
    ) {
      return;
    }
  };

  const closeModalHandler = () => {};

  return (
    <Modal onClose={props.onClose}>
      <div
        className="absolute top-4 right-8 md:top-[72px] md:right-[114px] text-2xl"
        onClick={props.onClose}
      >
        &times;
      </div>

      <form className="" onSubmit={submitFormHandler}>
        <h3 className="text-2xl font-semibold text-secondary mb-[47px] text-center">
          Career Opportunities
        </h3>
        <h2 className="text-1xl font-semibold text-secondary my-[47px]">
          Kindly submit your details, we will reach out as soon as there is an
          opening for you.
        </h2>
        <Input
          label="Full Name"
          id="name"
          value={name}
          name="name"
          placeholder="First Name Last Name"
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <Input
          label="Email Address"
          id="email"
          value={email}
          name="email"
          placeholder="example@email.com"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <Input
          label="Phone Number"
          id="number"
          value={number}
          name="number"
          placeholder="Phone Number"
          onChange={(e) => {
            setNumber(e.target.value);
          }}
        />
        <Input
          label="Job Interest"
          id="interest"
          value={interest}
          name="interest"
          placeholder="Job Interest"
          onChange={(e) => {
            setInterest(e.target.value);
          }}
        />
        {/* <Select
            label="Job Interest"
            id="interest"
            name="interest"
            placeholder=""
            options={[
              { name: "Job Interest", value: "", id: 1 },
              { name: "Both", value: "riby", id: 2 },
              { name: "Riby Marketplace Limited (RMP)", value: "rmp", id: 3 },
              { name: "Radeus Business Tech Limited", value: "radeus", id: 4 },
            ]}
            onChange={e => {
              setInterest(e.target.value);
            }}
          /> */}

        {/* <TextArea
            label="Additional Information"
            id="info"
            value={info}
            name="info"
            placeholder="Tell us more about Your Business interest"
            onChange={e => {
              setInfo(e.target.value);
            }}
          /> */}
        <div className="flex flex-col mb-[20px]">
          <label htmlFor="resume" className="secondary text-sm font-medium">
            Upload Resume
            <input
              type="file"
              name="resume"
              id="resume"
              accept="image/png, image/jpg, image/gif, image/jpeg"
              className="hidden"
            />{" "}
            <div className=" border-dotted border-2 border-[#E7E7E7] bg-[#F9FAFC] px-[28px] py-[18px] w-full mt-[24px]">
              <div className="text-[12px] text-secondary leadig-[20px]">
                Drag a file here or browse for a file to upload.
              </div>

              <div className="text-[10px] leading-[13px] text-[#718096]">
                Supported file types: JPEG, PNG. Max file size: 5MB
              </div>
            </div>
          </label>
        </div>

        <div className="w-full flex justify-center items-center">
          <button className="h-[56px] w-[221px] bg-button rounded text-sm text-white ">
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default CareerForm;
